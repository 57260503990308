import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Map = () => (
    <StaticQuery query={graphql`
    {
        wordpressPage(slug: { eq: "location" }) {
            acf {
                direction_title
                highway_title
                highway_heading
                highway_content
                scenic_route_title
                scenic_route_heading
                scenic_route_content
                garden_route_title
                garden_route_heading
                garden_route_content
                map_link
            }	
        }
    }
    `} render={props => (
        <div className="location-map">
            <div className="frame">
                <div className="direction-title" data-aos="fade-up">{props.wordpressPage.acf.direction_title}</div>
                <div className="line line1" data-aos="fade-up"></div>
                <div className="direction-item item-margin" data-aos="fade-up">
                    <div className="item-title">{props.wordpressPage.acf.highway_title}</div>
                    <div className="item-heading" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.highway_heading }}></div>
                    <div className="item-content" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.highway_content }}></div>
                </div>
                <div className="direction-item" data-aos="fade-up">
                    <div className="item-title">{props.wordpressPage.acf.scenic_route_title}</div>
                    <div className="item-heading" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.scenic_route_heading }}></div>
                    <div className="item-content" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.scenic_route_content }}></div>
                </div>
                <div className="direction-item" data-aos="fade-up">
                    <div className="item-title">{props.wordpressPage.acf.garden_route_title}</div>
                    <div className="item-heading" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.garden_route_heading }}></div>
                    <div className="item-content" dangerouslySetInnerHTML = {{ __html: props.wordpressPage.acf.garden_route_content }}></div>
                </div>
            </div>
            <div className="google-map" data-aos="fade-up">
                <iframe src={props.wordpressPage.acf.map_link} allowFullScreen></iframe>
            </div>
        </div>
    ) } />
);

export default Map;