import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Banner = () => (
    <StaticQuery query={graphql`
    {
        home: wordpressPage(title: { eq: "Home" }){
            acf {
                check_in_title
                check_out_title
                button_title
                trip_advisor_image {
                    source_url
                }
            }
        }
        location: wordpressPage(slug: { eq: "location" }) {
      			featured_media {
            		source_url
            }
    
        }
    }
    `} render={props => (
         <div className="location-banner banner">
            <div className="background-banner-type-3" style={{backgroundImage: 'url(' + props.location.featured_media.source_url + ')'}}>
                <div className="banner-bottom">
                    <div className="search-and-tripadvisor">
                        <div className="search-form">
                            <div className="check-in">
                                <i className="material-icons">date_range</i>
                                <input type="text" id="dt1" placeholder={ props.home.acf.check_in_title } />
                            </div>
                            <div className="check-out">
                                <i className="material-icons">date_range</i>
                                <input type="text" id="dt2" placeholder={ props.home.acf.check_out_title } />
                            </div>
                            <div className="search">
                                <button id="calsubmit">{ props.home.acf.button_title }</button>
                            </div>
                        </div>
                        <div className="tripadvisor">
                            <a href="https://www.tripadvisor.in/Hotel_Review-g12406123-d1018883-Reviews-138_Marine_Beachfront_Guesthouse-Sandbaai_Hermanus_Overstrand_Overberg_District_West.html" target="_blank">
                                <img src={ props.home.acf.trip_advisor_image.source_url } alt="tripadvisor" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    ) } />
);

export default Banner;