import "../css/fonts.css"
import "../css/style.scss"
import "../css/responsive.scss"
import "../css/material-icons.css"
import "../css/jquery-ui.css"

import React, { Component } from 'react'
import DefaultTemplate from "../templates/default"
import Banner from "../components/location/banner"
import Content from "../components/location/content"
import Map from "../components/location/map"
import Accommodation from "../components/location/accommodation"
import AOS from 'aos';
import 'aos/dist/aos.css';

class Location extends Component {
    
    componentDidMount(){
        AOS.init({
            duration : 2000,
            once: true
        })
    }
    render(){
        return  <DefaultTemplate>
                  <Banner />
                  <Content />
                  <Map />
                  <Accommodation />
                </DefaultTemplate>
    }
}
   
export default Location