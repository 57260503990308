import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const Content = () => (
    <StaticQuery query={graphql`
    {
        home: wordpressPage(title: { eq: "Home" }){
            acf {
                top_background_image {
                    source_url
                }
            }
        }
        location: wordpressPage(slug: { eq: "location" }) {
            acf {
                location_heading
                location_subheading
                wordpress_0m
                wordpress_0m_heading
                wordpress_0m_content
                wordpress_20m
                wordpress_20m_heading
                wordpress_20m_content
                wordpress_60m
                wordpress_60m_heading
                wordpress_60m_content
                wordpress_25km
                wordpress_25km_heading
                wordpress_25km_content
                wordpress_3km
                wordpress_3km_heading
                wordpress_3km_content
                wordpress_7km
                wordpress_7km_heading
                wordpress_7km_content
                wordpress_11km
                wordpress_11km_heading
                wordpress_11km_content
                wordpress_50km
                wordpress_50km_heading
                wordpress_50km_content
                wordpress_104km
                wordpress_104km_heading
                wordpress_104km_content
            }	
        }
    }
    `} render={props => (
        <div className="frame">
            <div className="location-content">
                <div className="top-background-image">
                    <img src={props.home.acf.top_background_image.source_url} />
                </div>
                <div className="title" data-aos="fade-up">{ props.location.acf.location_heading }</div>
                <div className="subtitle" data-aos="fade-up">{ props.location.acf.location_subheading }</div>
                <div className="distance-wrapper">
                    <div className="distance" data-aos="fade-up">
                        <div className="dot"></div>
                        <div className="left">{ props.location.acf.wordpress_0m }</div>
                        <div className="space"><hr /></div>
                        <div className="right">
                            <div className="heading">{ props.location.acf.wordpress_0m_heading }</div>
                            <div className="content">{ props.location.acf.wordpress_0m_content }</div>
                        </div>
                    </div>
                    <div className="distance" data-aos="fade-up">
                        <div className="dot"></div>
                        <div className="left">{ props.location.acf.wordpress_20m }</div>
                        <div className="space"><hr /></div>
                        <div className="right">
                            <div className="heading">{ props.location.acf.wordpress_20m_heading }</div>
                            <div className="content">{ props.location.acf.wordpress_20m_content }</div>
                        </div>
                    </div>
                    <div className="distance" data-aos="fade-up">
                        <div className="dot"></div>
                        <div className="left">{ props.location.acf.wordpress_60m }</div>
                        <div className="space"><hr /></div>
                        <div className="right">
                            <div className="heading">{ props.location.acf.wordpress_60m_heading }</div>
                            <div className="content">{ props.location.acf.wordpress_60m_content }</div>
                        </div>
                    </div>
                    <div className="distance" data-aos="fade-up">
                        <div className="dot"></div>
                        <div className="left">{ props.location.acf.wordpress_25km }</div>
                        <div className="space"><hr /></div>
                        <div className="right">
                            <div className="heading">{ props.location.acf.wordpress_25km_heading }</div>
                            <div className="content">{ props.location.acf.wordpress_25km_content }</div>
                        </div>
                    </div>
                    <div className="distance" data-aos="fade-up">
                        <div className="dot"></div>
                        <div className="left">{ props.location.acf.wordpress_3km }</div>
                        <div className="space"><hr /></div>
                        <div className="right">
                            <div className="heading">{ props.location.acf.wordpress_3km_heading }</div>
                            <div className="content">{ props.location.acf.wordpress_3km_content }</div>
                        </div>
                    </div>
                    <div className="distance" data-aos="fade-up">
                        <div className="dot"></div>
                        <div className="left">{ props.location.acf.wordpress_7km }</div>
                        <div className="space"><hr /></div>
                        <div className="right">
                            <div className="heading">{ props.location.acf.wordpress_7km_heading }</div>
                            <div className="content">{ props.location.acf.wordpress_7km_content }</div>
                        </div>
                    </div>
                    <div className="distance" data-aos="fade-up">
                        <div className="dot"></div>
                        <div className="left">{ props.location.acf.wordpress_11km }</div>
                        <div className="space"><hr /></div>
                        <div className="right">
                            <div className="heading">{ props.location.acf.wordpress_11km_heading }</div>
                            <div className="content">{ props.location.acf.wordpress_11km_content }</div>
                        </div>
                    </div>
                    <div className="distance" data-aos="fade-up">
                        <div className="dot"></div>
                        <div className="left">{ props.location.acf.wordpress_50km }</div>
                        <div className="space"><hr /></div>
                        <div className="right">
                            <div className="heading">{ props.location.acf.wordpress_50km_heading }</div>
                            <div className="content">{ props.location.acf.wordpress_50km_content }</div>
                        </div>
                    </div>
                    <div className="distance" data-aos="fade-up">
                        <div className="dot"></div>
                        <div className="left">{ props.location.acf.wordpress_104km }</div>
                        <div className="space"><hr /></div>
                        <div className="right">
                            <div className="heading">{ props.location.acf.wordpress_104km_heading }</div>
                            <div className="content">{ props.location.acf.wordpress_104km_content }</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) } />
);

export default Content;